<template>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>MA CARTE</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Accueil</a> &nbsp;</li>
              <li class="breadcrumb-item active">ma carte</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
        <div class="container-fluid">
            <div class="card card-success" v-if="this.currentUser.hasCard">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-xl-6">
                            <div class="card mb-2 shadow">
                                <img v-if="carteRecto" :src="carteRecto" alt="Carte Recto">
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-6">
                            <div class="card mb-2 shadow">
                                <img v-if="carteVerso" :src="carteVerso" alt="Carte Verso">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-success text-center" v-else style="background-color: #C8C8C8;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <img v-bind:src="defaultImage" style="height: 500px;"/>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import axios from "axios";
import defaultCard from "../assets/images/smartcarddefault.png"
import authHeader from "@/services/auth-header";
import {Field, Form, ErrorMessage} from "vee-validate";
import * as yup from "yup";
const API_URL = "https://smartcard225.com:7001/api/v1/";

  export default {
    // Options du composant
    name: 'MaCarte',
    data() {
      return {
        defaultImage: defaultCard,
        carterecto: "",
        carteVerso: "",
      };
    },
    computed: {
        currentUser() {
        if (this.$store.state.auth.user && this.$store.state.auth.user.client) {
            this.client = this.$store.state.auth.user.client
            console.log(this.client);
            return this.client;

        } else {
            return {};
        }
        },
    },
    mounted() {
        if (!this.currentUser || Object.keys(this.currentUser).length === 0) {
            this.handleLogout();
        } else {
            const userid = this.currentUser._id;

            axios.get(`https://smartcard225.com:7001/api/v1/images/carteRectoClient/${userid}`, {
                responseType: 'arraybuffer',
            })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                this.carteRecto = URL.createObjectURL(blob);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                this.isLoading = false;
            });

            axios.get(`https://smartcard225.com:7001/api/v1/images/carteVersoClient/${userid}`, {
                responseType: 'arraybuffer',
            })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                this.carteVerso = URL.createObjectURL(blob);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
        }
    },
    created() {
      // Logique exécutée lors de la création du composant
    },
    methods: {
        handleLogout() {
        this.$router.push('/login');
        },

        logOut() {
        this.$store.dispatch('auth/logout');
        this.$router.push('/login');
        }
    },
    // Autres options du composant
  };
</script>
  
  <style scoped>
  /* Styles spécifiques au composant */
  </style>
  