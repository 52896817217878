<template >
    <div class="d-flex flex-column flex-root login-view" id="kt_app_root" >
        <div class="d-flex flex-column flex-column-fluid flex-lg-row">
            <div class="d-flex flex-center w-lg-50 pt-5 pt-lg-0 px-10">
                <div class="d-flex flex-column">
                    <a href="#" class="mb-7">
                        <img alt="Logo" class="img-fluid" width="300px" src="@/assets/media/logos/logo.png" />
                    </a>
                    <h2 class="fw-normal m-0 text-center">LA CARTE DE L'AVENIR</h2>
                </div>
            </div>
            <div class="d-flex flex-center w-lg-50 p-10">
                <div class="card card-container rounded-3 w-md-550px">
                    <div class="card-body p-5 p-lg-20">
                        <Form @submit="handleLogin" :validation-schema="schema">
                            <div class="text-center mb-11">
                                <div class="form-group mb-5">
                                    <div v-if="message" class="alert alert-danger" role="alert">
                                        {{ message }}
                                    </div>
                                </div>
                                <h1 class="text-dark fw-bolder mb-3">Connexion</h1>
                                <div class="text-gray-500 fw-semibold fs-6">Connectez-vous à votre espace</div>
                            </div>
                            <div class="form-group">
                                <label for="email" style="font-size: 1.2em">Email</label>
                                <Field name="email" type="email" class="form-control" style="height:3em" />
                                <ErrorMessage name="email" class="error-feedback text-danger" />
                            </div>
                            <div class="form-group">
                                <label for="password" style="font-size: 1.2em">Mot de passe</label>
                                <Field name="password" type="password" class="form-control" style="height:3em" />
                                <ErrorMessage name="password" class="error-feedback text-danger" />
                            </div>
                            <div class="form-group text-center">
                                <button class="btn btn-primary btn-lg" :disabled="loading">
                                    <span>Je me connecte &nbsp;</span>
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                </button>
                            </div>
                            <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                <div></div>
                                <a href="#" class="link-primary">Mot de passe oublié ?</a>
                            </div>
                            <div class="text-gray-500 text-center fw-semibold fs-6">Pas encore inscrit ?
                            <a href="https://smartcard.smartcard225.com/create-card" class="link-primary">Inscrivez-vous ici</a></div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import '@/assets/plugins/global/plugins.bundle.js';
//import '@/assets/js/scripts.bundle.js';
//import '@/assets/js/custom/authentication/sign-in/general.js';


import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .email("Email invalide !")
        .required("Votre email est obligatoire !"),
      password: yup
        .string()
        .required("Votre mot de passe est obligatoire !"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};

</script>

<style scoped>
    
    .login-view {
    background-image: url('@/assets/media/bg/bg.jpg');
    }

    @media screen and (max-width: 990px) {
    .login-view {
        background-image: url('@/assets/media/bg/bgmobile.jpg');
    }
    }

    .login-view {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
  }

    @import '@/assets/plugins/global/plugins.bundle.css';
    @import '@/assets/css/style.bundle.css';
</style>
