import axios from "axios"

//const API_URL = "https://smartcard225.com:7001/api/v1/"
const API_URL = "https://smartcard225.com:7001/api/v1/"

class AuthService {
    login(user) {
        return axios
            .post(API_URL + "authClient/login", {
                email: user.email,
                password: user.password,
            })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem("user", JSON.stringify(response.data))
                }
                return response.data
            })
    }



    logout() {
        localStorage.removeItem("user")
    }
}

export default new AuthService()
