import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login.vue'
import Dashboard from '../components/Dashboard.vue'
import Stats from "@/components/Stats.vue";
import MaCarte from "@/components/MaCarte.vue";
import LesCartes from "@/components/LesCartes.vue";
import Parametres from "@/components/Parametres.vue";
import Employes from "@/components/Employes.vue";
import AjouterEmploye from "@/components/AjouterEmploye.vue";

const Profil = () => import('../components/Profil.vue')
const ProfilUpdateAdmin = () => import('../components/ProfilUpdateAdmin.vue')

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
      children: [
          {path: '', redirect: '' },
          {path: '', name: 'stats', component: Stats},
          {path: 'macarte', name: 'macarte', component: MaCarte},
          {path: 'lescartes', name: 'lescartes', component: LesCartes},
          {path: 'parametres', name: 'parametres', component: Parametres},
          {path: 'employes', name: 'employes', component: Employes},
          {path: 'ajouteremploye', name: 'ajouteremploye', component: AjouterEmploye},
          {path: 'profil', name: 'profil', component: Profil,},
          {path: 'profilUpdateAdmin', name: 'profilUpdateAdmin', component: ProfilUpdateAdmin,},
      ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
