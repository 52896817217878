<template>
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>LISTE DE L'EFFECTIF</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="/">Accueil</a> &nbsp;</li>
            <li class="breadcrumb-item active">Liste de l'effectif</li>
          </ol>
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">
      
      <div class="row mb-2">
        <div class="col-sm-6">
          <button type="button" class="btn btn-primary">
           <a href="/ajouteremploye" style="color: white;"> Ajouter un employé</a>
          </button>
        </div>
      </div>

      <table ref="myTable" class="display">
        <thead>
          <tr>
            <th>Nom et Prénoms</th>
            <th>Poste</th>
            <th>Contact</th>
            <th>Email</th>
            <th>Statut</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.nomComplet }}</td>
            <td>{{ user.poste }}</td>
            <td>{{ user.phoneOne }}</td>
            <td>{{ user.email }}</td>
            <td> <span v-if="user.isActive" style="color: green;">Actif</span>
                <span v-else style="color: orange;">Inactif</span>
            </td>
            <td>
              <div class="btn-group">
                  <button type="button" class="btn btn-default btn-sm" @click="() => voirEmploye(user._id)">
                    <i class="far fa-eye" style="color: blue;"></i>
                  </button>
                  <button type="button" class="btn btn-default btn-sm">
                    <a :href="'/profilUpdateAdmin?id=' + user.id">
                      <i class="fas fa-edit" style="color: orangered;"></i>
                    </a>
                  </button>
                </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import authHeader from "@/services/auth-header";
const API_URL = "https://smartcard225.com:7001/api/v1/";
import Swal from 'sweetalert2';

export default {
  // Options du composant
  name: 'Employes',
  data() {
    return {
      photo:"",
      users: [],
      dataTable: null,
      datatableOptions: {
          language: {
          search: "Rechercher :",
          paginate: {
            first: "Premier",
            last: "Dernier",
            next: "Suivant",
            previous: "Précédent"
          },
          lengthMenu: "Afficher _MENU_ entrées",
          info: "Affichage de _START_ à _END_ sur un total de _TOTAL_ entrées"
        }
      }
    };
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.user && this.$store.state.auth.user.client) {
        return this.$store.state.auth.user.client;
      } else {
        return {};
      }
    },
  },
  mounted() {
    // Logique exécutée lors du montage du composant
    this.fetchUsers(); // Appelez la méthode fetchUsers pour récupérer les données des utilisateurs
  },
  beforeDestroy() {},
  created() {
    // Logique exécutée lors de la création du composant
  },
  methods: {
    fetchUsers() {
      // Utilisez Axios pour effectuer une requête à votre API et récupérer les données des utilisateurs
        const idEnt = this.currentUser.entrepriseID._id;
        axios
          .get(
            `https://smartcard225.com:7001/api/v1/statsEntreprises/lEntreprise/chiffres/${idEnt}`,
            { headers: authHeader() }
          )
          .then((response) => {
            this.users = response.data.employes;
            this.$nextTick(() => {
            this.dataTable = $(this.$refs.myTable).DataTable(this.datatableOptions);
          });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setTimeout(() => {
              this.isLoading = false;
            }, 1000);
          });
    },
    voirEmploye(employeId) {
    axios
      .get(`https://smartcard225.com:7001/api/v1/clients/${employeId}`, { headers: authHeader() })
      .then((response) => {
        this.nom = response.data.client.nomComplet;
        this.poste = response.data.client.poste;
        this.contact = response.data.client.phoneOne;
        this.email = response.data.client.email;

        return axios.get(`https://smartcard225.com:7001/api/v1/images/photoClient/${employeId}`, {
          responseType: 'arraybuffer',
        });
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        this.photo = URL.createObjectURL(blob);

        Swal.fire({
          title: this.nom,
          html: 'Poste: ' + this.poste + '<br>' +
                'Contact: ' + this.contact + '<br>' +
                'Email: ' + this.email + '<br>',
          imageUrl: this.photo,
          imageWidth: 300,
          imageHeight: 300,
          imageAlt: 'Custom image',
          customClass: 'custom-circle-image', // Classe CSS personnalisée pour appliquer le style circulaire à l'image
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

    initializeDataTable() {
      // Initialisez DataTables sur le tableau avec l'ID "myTable"
      $(this.$el)
        .find("#myTable")
        .DataTable();
    },
  },
};
</script>

<style scoped>
.custom-circle-image {
  border-radius: 50%;
}
</style>
