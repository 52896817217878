<template>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>LES CARTES</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Accueil</a> &nbsp;</li>
              <li class="breadcrumb-item active">Les Cartes</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4" v-for="user in sortedUsers" :key="user.id">
            <!-- Widget: user widget style 1 -->
            <div class="card card-widget widget-user">
              <!-- Add the bg color to the header using any of the bg-* classes -->
              <div class="widget-user-header" style="background-color: #0522e4;">
                <h3 class="widget-user-username" style="color: white;">{{ user.nomComplet }}</h3>
                <h5 class="widget-user-desc" style="color: white;"><b>{{ user.poste }}</b></h5>
              </div>
              <div class="widget-user-image">
                <img class="img-circle elevation-2 img-responsive" :src="photos[user.id]" :alt="user.nomComplet" style="width:90px !important ; height:90px !important;"/>
              </div><br>
              <div class="card-footer">
                <div class="row">
                  <div class="col">
                    <button type="button" class="btn btn-block btn-lg" style="background-color: #F95403; color: white;" v-if="user.hasCard" @click="() => voirEmployeCarte(user._id)">
                        VOIR LA CARTE
                    </button>
                    <button type="button" class="btn btn-block btn-secondary btn-lg" v-else disabled  @click="() => voirEmployeCarte(user._id)">
                        PAS DE CARTE
                    </button>

                  </div>
                </div>
                <!-- /.row -->
              </div>
            </div>
            <!-- /.widget-user -->
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from "axios";
  import defaultCard from "../assets/images/smartcarddefault.png";
  import authHeader from "@/services/auth-header";
  import Swal from 'sweetalert2';
  
  const API_URL = "https://smartcard225.com:7001/api/v1/";
  
  export default {
    name: 'MaCarte',
    data() {
      return {
        defaultImage: defaultCard,
        carteRecto: "",
        carteVerso: "",
        photos: {},
        users: [],
      };
    },
    computed: {
      currentUser() {
        if (this.$store.state.auth.user && this.$store.state.auth.user.client) {
          return this.$store.state.auth.user.client;
        } else {
          return {};
        }
      },
      sortedUsers() {
        return this.users.sort((a, b) => a.nomComplet.localeCompare(b.nomComplet));
      },
    },
    mounted() {
      if (!this.currentUser || Object.keys(this.currentUser).length === 0) {
        this.handleLogout();
      } else {
        this.fetchUsers();
      }
    },
    methods: {
      fetchUsers() {
        const idEnt = this.currentUser.entrepriseID._id;
        axios
          .get(`https://smartcard225.com:7001/api/v1/statsEntreprises/lEntreprise/chiffres/${idEnt}`, {
            headers: authHeader(),
          })
          .then(response => {
            this.users = response.data.employes;
            this.fetchPhotos();
          })
          .catch(error => {
            console.error(error);
          });
      },
      fetchPhotos() {
        this.users.forEach(user => {
          axios
            .get(`https://smartcard225.com:7001/api/v1/clients/${user.id}`, { headers: authHeader() })
            .then((response) => {
              return axios.get(`https://smartcard225.com:7001/api/v1/images/photoClient/${user.id}`, {
                responseType: 'arraybuffer',
              });
            })
            .then((response) => {
              const blob = new Blob([response.data], { type: response.headers['content-type'] });
              this.photos = Object.assign({}, this.photos, { [user.id]: URL.createObjectURL(blob) });
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        });
      },
      voirEmployeCarte(employeId) {
        axios
            .get(`https://smartcard225.com:7001/api/v1/clients/${employeId}`, { headers: authHeader() })
            .then((response) => {
            return axios.get(`https://smartcard225.com:7001/api/v1/images/carteRectoClient/${employeId}`, {
                responseType: 'arraybuffer',
            });
            })
            .then((response) => {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            this.photoRecto = URL.createObjectURL(blob);

            return axios.get(`https://smartcard225.com:7001/api/v1/images/carteVersoClient/${employeId}`, {
                responseType: 'arraybuffer',
            });
            })
            .then((response) => {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            this.photoVerso = URL.createObjectURL(blob);

            const imageElement1 = `<img src="${this.photoRecto}" style="width: 300px; height: 300px;">`;
            const imageElement2 = `<img src="${this.photoVerso}" style="width: 300px; height: 300px;">`;

            Swal.fire({
                html: `${imageElement1}&nbsp;&nbsp;${imageElement2}`,
                width: 1000,
                padding: '3em',
                imageAlt: 'Custom image',
                customClass: {
                content: 'custom-modal',
                },
                heightAuto: false,
            });
            })
            .catch((error) => {
            console.error(error);
            })
            .finally(() => {
            this.isLoading = false;
            });
        },

      handleLogout() {
        this.$router.push('/login');
      },
    },
  };
  </script>
  
  <style scoped>
  .swal2-popup {
  width: auto !important;
  max-width: 800px;
  padding: 20px;
}

.custom-modal {
  height: 500px;
  overflow-y: auto;
}

.custom-modal .swal2-content {
  padding: 0;
}
  </style>
  