<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
  },
};
</script>

<style>
/* @import url('@/assets/punkr/assets/css/dashlite.css');
@import url('@/assets/punkr/assets/css/theme.css'); */

/* admin lte  */
@import url('@/assets/plugins/fontawesome-free/css/all.min.css');  
@import url('@/assets/dist/css/adminlte.min.css');
  
</style>
