<template class="hold-transition sidebar-mini">
  <div class="wrapper">
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="/" class="nav-link">Accueil</a>
      </li>
    </ul>

  </nav>
  <!-- /.navbar -->

  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="/" class="brand-link">
      <img src="https://smartcard.smartcard225.com/assets/revslider/assets/favicon.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-bold text-center">SMARTCARD</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img :src="photo" class="img-circle elevation-3" alt="Photo">
        </div>
        <div class="info">
          <a href="#" class="d-block">{{currentUser.nomComplet}}</a>
        </div>
      </div>



      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item">
            <a href="/" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Tableau de bord
              </p>
            </a>
          </li>
          <li class="nav-item" v-if="this.currentUser && this.currentUser.roleID && this.currentUser.roleID.code == 'particulier'">
            <a href="/profil" class="nav-link">
              <i class="nav-icon fas fa-user"></i>
              <p>
                Mon Profil
              </p>
            </a>
          </li>
          <li class="nav-item" v-if="this.currentUser && this.currentUser.roleID && this.currentUser.roleID.code == 'adminEnt'">
            <a href="/employes" class="nav-link">
              <i class="nav-icon fas fa-user"></i>
              <p>
                Employés
              </p>
            </a>
          </li>
          <li class="nav-item" v-if="this.currentUser && this.currentUser.roleID && this.currentUser.roleID.code == 'particulier'">
            <a href="/macarte" class="nav-link">
              <i class="nav-icon fas fa-id-card"></i>
              <p>
                Ma carte
              </p>
            </a>
          </li>
          <li class="nav-item" v-if="this.currentUser && this.currentUser.roleID && this.currentUser.roleID.code == 'adminEnt'">
            <a href="/lescartes" class="nav-link">
              <i class="nav-icon fas fa-id-card"></i>
              <p>
                Les cartes
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a href="/parametres" class="nav-link">
              <i class="nav-icon fas fa-cogs"></i>
              <p>
                Paramètres
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="logOut" href="#">
              <i class="fa fa-sign-out-alt"></i>
              <font-awesome-icon icon="sign-out-alt" /> Déconnexion
            </a>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->


    <!-- Main content -->
    <section class="content">

      <router-view></router-view>

    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
  <footer class="main-footer text-center">
    <!-- <div class="float-right d-none d-sm-block">
      <b>Version</b> 3.2.0
    </div> -->
    <strong>Copyright &copy; 2023 <a href="https://sm-ic.com/" target="_blank">SMART</a>.</strong> Tous droits reservés
  </footer>

  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>
  <!-- /.control-sidebar -->
</div>
<!-- ./wrapper -->







</template>


<script>

/*import '../../public/assets/js/bundle.js';
import '../../public/assets/js/scripts.js';
import '../../public/assets/js/charts/gd-default.js';*/
// import '@/assets/dist/js/adminlte.min.js'

import axios from 'axios'

export default {
  name: 'Dashboard',
  data(){
    return {
      photo: ""
    };
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.user && this.$store.state.auth.user.client) {
        return this.$store.state.auth.user.client;
      } else {
        return {};
      }
    },
  },
  mounted() {
    if (!this.currentUser || Object.keys(this.currentUser).length === 0) {
      this.handleLogout();
    }
    else{
      const userid = this.currentUser._id;

      axios.get(`https://smartcard225.com:7001/api/v1/images/photoClient/${userid}`, {
        responseType: 'arraybuffer',
      })
      .then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        this.photo = URL.createObjectURL(blob);

      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
    }
  },
  methods: {
    handleLogout() {
      this.$router.push('/login');
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
};


</script>

<style scoped>

</style>
