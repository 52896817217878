<template>
  <!-- Main content -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>TABLEAU DE BORD</h1>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>

  <section class="content">
    <div class="container-fluid">
      <!-- STATS POUR PARTICULIER -->
      <div class="row" v-if="this.currentUser && this.currentUser.roleID && this.currentUser.roleID.code == 'particulier'">
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div class="small-box bg-smart-1 text-center">
            <div class="inner">
              <h3 style="color: white">{{ scansEffectues }}</h3>
              <h3 style="color: white">
                Scans<br />
                effectués
              </h3>
            </div>
            <div class="icon">
              <i class="ion ion-qr-scanner" style="color: white"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div class="small-box bg-smart-1 text-center">
            <div class="inner">
              <h3 style="color: white">{{ contactsEnregistres }}</h3>
              <h3 style="color: white">
                Contacts <br />
                enregistrés
              </h3>
            </div>
            <div class="icon">
              <i class="fas fa-address-book" style="color: white"></i>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-sm-6 col-xs-12"
          v-for="visite in visites"
          :key="visite._id"
        >
          <div
            class="small-box"
            :style="{
              backgroundColor: visite.reseauID
                ? visite.reseauID.couleur
                : 'gray',
            }"
          >
            <div class="inner text-center">
              <h3 style="color: white">{{ visite.nbreVisite }}</h3>
              <h3 style="color: white">
                Visites <br />
                {{ visite.reseauID ? visite.reseauID.libelle : "" }}
              </h3>
            </div>
            <div class="icon">
              <i
                :class="
                  'fab fa-' + (visite.reseauID ? visite.reseauID.icone : '')
                "
                style="color: white"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <!-- STATS POUR ADMIN ENTREPRISE -->

      <div class="row" v-if="this.currentUser && this.currentUser.roleID && this.currentUser.roleID.code == 'adminEnt'">
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div class="small-box bg-smart-1 text-left">
            <div class="inner">
              <h3 style="color: white">{{ scanEntreprise }}</h3>
              <h3 style="color: white">
                Scans<br />
                effectués
              </h3>
            </div>
            <div class="icon">
              <i class="ion ion-qr-scanner" style="color: white"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div class="small-box bg-smart-1 text-left">
            <div class="inner">
              <h3 style="color: white">{{ employesEntreprise }}</h3>
              <h3 style="color: white">
                Employés <br />
                enregistrés
              </h3>
            </div>
            <div class="icon">
              <i class="fas fa-address-book" style="color: white"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div class="small-box bg-smart-1 text-left">
            <div class="inner">
              <h3 style="color: white">{{ employesEntrepriseActifs }}</h3>
              <h3 style="color: white">
                Employés <br />
                actifs
              </h3>
            </div>
            <div class="icon">
              <i class="fas fa-user-check" style="color: white"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div class="small-box bg-smart-1 text-left">
            <div class="inner">
              <h3 style="color: white">{{ employesEntrepriseInactifs }}</h3>
              <h3 style="color: white">
                Employés <br />
                inactifs
              </h3>
            </div>
            <div class="icon">
              <i class="fas fa-user-times" style="color: white"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- STATS POUR CLIENT ENTREPRISE -->

      <!-- STATS POUR ADMINISTRATION SMARTCARD -->

      <div class="row">
        <div class="col-12 hello" ref="chartdiv"></div>
      </div>
      <!-- /.row -->
      <!-- /.row (main row) -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</template>

<script>
import axios from "axios";
import authHeader from "../services/auth-header";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
  data: function () {
    return {
      isLoading: true,
      photo: "",
      logoEntreprise: "",
      nomComplet: "",
      poste: "",
      entreprise: "",
      phone1: "",
      phone2: "",
      email: "",
      localisation: "",
      siteweb: "",
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      whatsapp: "",
      scansEffectues: 0,
      visites: 0,
      contactsEnregistres: 0,

      scanEntreprise: 0,
      employesEntreprise: 0,
      employesEntrepriseActifs: 0,
      employesEntrepriseInactifs: 0,
    };
  },
  name: "Dashboard",
  computed: {
    currentUser() {
      if (this.$store.state.auth.user && this.$store.state.auth.user.client) {
        return this.$store.state.auth.user.client;
      } else {
        return {};
      }
    },
  },
  mounted() {
    //chart 1
    let root = am5.Root.new(this.$refs.chartdiv);
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      })
    );

    // Define data
    let data = [
      {
        category: "Janvier",
        value1: 1000,
        value2: 588,
      },
      {
        category: "Février",
        value1: 1200,
        value2: 1800,
      },
      {
        category: "Mars",
        value1: 850,
        value2: 1230,
      },
      {
        category: "Avril",
        value1: 125,
        value2: 205,
      },
      {
        category: "Mai",
        value1: 650,
        value2: 300,
      },
      {
        category: "Juin",
        value1: 500,
        value2: 402,
      },
    ];

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "category",
      })
    );
    xAxis.data.setAll(data);

    // Create series
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Nombre de Scans",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value1",
        categoryXField: "category",
      })
    );
    series1.data.setAll(data);

    // Add legend
    let legend = chart.children.push(am5.Legend.new(root, {}));
    legend.data.setAll(chart.series.values);

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    this.root = root;
  },
  beforeDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  },
  created() {
    if (!this.currentUser || Object.keys(this.currentUser).length === 0) {
      this.handleLogout();
    }

    const cliCode = this.currentUser.code;
    const role = this.currentUser.roleID.code;
    const idEnt = this.currentUser.entrepriseID._id;
    
    if (role== "adminEnt") {
      axios
          .get(
            `https://smartcard225.com:7001/api/v1/statsEntreprises/lEntreprise/chiffres/${idEnt}`,
            { headers: authHeader() }
          )
          .then((response) => {
            this.scanEntreprise = response.data.nbreScans;
            this.employesEntreprise = response.data.employes.length;
            this.employesEntrepriseActifs = response.data.employesActifs.length;
            this.employesEntrepriseInactifs = response.data.employesInactifs.length;

          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setTimeout(() => {
              this.isLoading = false;
            }, 1000);
          });
    }

    axios
      .get(
        `https://smartcard225.com:7001/api/v1/clients/getByCodeForStat/${cliCode}`,
        { headers: authHeader() }
      )
      .then((response) => {
        var client = response.data.client;
        const clientID = client._id;

        this.nomComplet = client.nomComplet;
        this.phone1 = client.phoneOne;
        this.phone2 = client.phoneTwo;
        this.email = client.email;
        axios
          .get(
            `https://smartcard225.com:7001/api/v1/statsClients/leClient/chiffres/${clientID}`,
            { headers: authHeader() }
          )
          .then((response) => {
            this.visites = response.data.chiffres.visites;
            this.contactsEnregistres =
              response.data.chiffres.contactsEnregistres;
            this.scansEffectues = response.data.chiffres.scansEffectues;

          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setTimeout(() => {
              this.isLoading = false;
            }, 1000);
          });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      });
  },
  methods: {
    handleLogout() {
      this.$router.push("/login");
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.hello {
  width: 100%;
  height: 500px;
}
.bg-smart-1 {
  background-color: #ff740a !important;
}
.bg-smart-2 {
  background-color: #d55e03 !important;
}
.bg-smart-3 {
  background-color: #c45600 !important;
}
</style>
