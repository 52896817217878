<template>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>PARAMETRES</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="/">Accueil &nbsp;</a></li>
              <li class="breadcrumb-item active">Paramètres</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
  
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-12">
          <div class="info-box">
            <span class="info-box-icon bg-danger"><i class="far fa-flag"></i></span>
            <div class="info-box-content">
              <td>
                <button type="button" class="btn btn-block btn-danger btn-lg" @click="confirmerDesactivationCompte">DESACTIVER MON COMPTE</button>
              </td>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import authHeader from "@/services/auth-header";
  import { Field, Form, ErrorMessage } from "vee-validate";
  import * as yup from "yup";
  const API_URL = "https://smartcard225.com:7001/api/v1/";
  import Swal from 'sweetalert2';
  
  export default {
    name: 'MaCarte',
    data() {
      return {
        // Données du composant
      };
    },
    computed: {
      currentUser() {
        if (this.$store.state.auth.user && this.$store.state.auth.user.client) {
          this.client = this.$store.state.auth.user.client;
          console.log(this.client);
          return this.client;
        } else {
          return {};
        }
      },
    },
    methods: {
      confirmerDesactivationCompte() {
        Swal.fire({
          title: "Êtes-vous sûr(e) de vouloir désactiver votre compte ?",
          text: "Cette action est irréversible.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Oui",
          cancelButtonText: "Annuler",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.desactiverCompte();
          }
        });
      },
      desactiverCompte() {
        const userid = this.currentUser._id
        axios.patch(`https://smartcard225.com:7001/api/v1/clients/desactiver/${userid}`, { headers: authHeader() })
        .then((response) => {
            if (response) {
              this.logOut();
            }            
          })
          .catch((error) => {
            console.log(error)
          });
      },
      logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
    },
    mounted() {
      // Logique exécutée après le rendu du composant
    },
    created() {
      // Logique exécutée lors de la création du composant
    },
  };
  </script>
  
  <style scoped>
  /* Styles spécifiques au composant */
  </style>
  