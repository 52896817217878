<template>
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>AJOUTER EMPLOYE</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="/">Accueil &nbsp;</a></li>
            <li class="breadcrumb-item"><a href="/employes">Liste employés &nbsp;</a></li>
            <li class="breadcrumb-item active">Ajouter un employé</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header p-2">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <a class="nav-link" href="#settingsPerso" data-toggle="tab">Informations Personnelles</a>
              </li>
            </ul>
          </div><!-- /.card-header -->
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane active" id="settingsPerso">
                <Form class="form-horizontal" :validation-schema="schemaInfosPerso" ref="form" v-on:submit="saveInfosPersonnelle">
                  <div class="form-group row">
                    <div class="photo m-2 img-upload">
                      <div class="team-img text-center" style="display: block">
                        <label for="img_file_up" class="images">
                          <img
                            v-if="!isPhotoSelected"
                            src="/assets/images/avatar.png"
                            class="img_prv img-fluid rounded-circle hover_img1"
                            alt="image"
                          />
                          <img
                            v-else
                            :src="selectedPhotoURL"
                            class="img-circle elevation-2 img-responsive"
                            alt="image" width="200" height="200"
                          />
                        </label>
                        <input
                          name="photo"
                          id="img_file_up"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          style="display: none !important"
                          @change="photoSelected"
                        />
                      </div>
                      <div class="text-center" >
                        <ErrorMessage name="photo" class="error-feedback text-danger" />
                      </div>
                    </div>
                    <label for="nomComplet" class="col-sm-2 col-form-label">Nom complet</label>
                    <div class="col-sm-10">
                      <Field name="nomComplet" id="nomComplet" type="text" class="form-control" placeholder="Nom complet" v-model="client.nomComplet" />
                      <ErrorMessage name="nomComplet" class="error-feedback text-danger" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="posteProfession" class="col-sm-2 col-form-label">Poste/Profession</label>
                    <div class="col-sm-10">
                      <Field name="posteProfession" id="posteProfession" type="text" class="form-control" placeholder="Poste/Profession" v-model="client.poste" />
                      <ErrorMessage name="posteProfession" class="error-feedback text-danger" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="emailPerso" class="col-sm-2 col-form-label">Email</label>
                    <div class="col-sm-10">
                      <Field name="emailPerso" id="emailPerso" type="email" class="form-control" placeholder="Votre Email" v-model="client.email" />
                      <ErrorMessage name="emailPerso" class="error-feedback text-danger" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="phoneNumber" class="col-sm-2 col-form-label">Téléphone</label>
                    <div class="col-sm-10">
                      <Field name="phoneNumber" id="phoneNumber" type="number" class="form-control" placeholder="Votre numero téléphonique" v-model="client.phoneOne" />
                      <ErrorMessage name="phoneNumber" class="error-feedback text-danger" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="offset-sm-2 col-sm-10">
                      <button class="btn btn-primary" type="submit" @click="" :disabled="isButtonDisabledInfosPerso">
                        Enregistrer
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div><!-- /.card-body -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Dashboard",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schemaInfosPerso = yup.object().shape({
      photo: yup
      .string()
      .when('isPhotoSelected', (isPhotoSelected, schema) => {
        return isPhotoSelected
          ? schema.required('La photo de profil est obligatoire !')
          : schema;
      }),
      nomComplet: yup.string().required("Le nom et prénom(s) est requis !"),
      posteProfession: yup.string().required("Le poste ou profession est obligatoire !"),
      phoneNumber: yup.string().required("Le numéro de téléphone est obligatoire !"),
    });

    return {
      loading: false,
      isPhotoSelected: false,
      selectedPhotoURL: "",
      schemaInfosPerso,
      client: {
        nomComplet: "",
        poste: "",
        email: "",
        phoneOne: "",
      },
      submit: false,
    };
  },
  methods: {
    photoSelected(event) {
      const file = event.target.files[0];
      if (file) {
        this.isPhotoSelected = true;
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedPhotoURL = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.isPhotoSelected = false;
        this.selectedPhotoURL = "";
      }
    },
    saveInfosPersonnelle() {
      this.submit = true;
      alert('ddd');
      this.submit = false;
    },
  },
};
</script>

<style scoped>
/* Component-specific CSS */
</style>